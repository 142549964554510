.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

p {
  color: #ffffff;
}

@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(100vw);
  }
  100% {
    -moz-transform: translateX(-200%);
  }
}
@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100vw);
  }
  100% {
    -webkit-transform: translateX(-200%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(100vw);
    -webkit-transform: translateX(100vw);
    transform: translateX(100vw);
  }
  100% {
    -moz-transform: translateX(-200%);
    -webkit-transform: translateX(-200%);
    transform: translateX(-200%);
  }
}

.Bulbasaur {
  width: 100px;
  height: 100px;
  -moz-animation: scroll-left 24s linear infinite;
  -webkit-animation: scroll-left 24s linear infinite;
  animation: scroll-left 24s linear infinite;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.center-cropped {
  object-fit: scale-down; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  height: 100%;
  width: 100%;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
